body, #body {
  position: relative;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}
.prod--link {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  margin: 10px 0px;
  padding: 0px 10px;
  width: 20%;
}
.form-control::-webkit-input-placeholder {
  color: red;
}
.prod--link:hover {
  color: inherit;
}
.add__btn {
  font-size: 22px;
  font-weight: 600;
  color: black;
}
.link {
    display: inline-block;
    text-decoration: none;
    color: inherit;
}
.link:hover {
  color: inherit;
}
@media(max-width: 768px) {
  .prod--link {
    color: inherit;
    margin: 0px 0px;
    padding: 0px 0px;
    width: 49%;
}
}
#container {
        max-width: 1000px;
    }
@media (max-width: 1090px) {
   #container {
        max-width: 800px;
    }
    #group--footer {
     width: 740px;
    }
}

 


#table-head-cont {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  height: auto;
}
#thead-cont {
  /* position: fixed; */
  z-index: 10;
  left: 0;
  right: 0;
  height: auto;
  padding:0px;
}

#mobile {
  display: none;
  padding-top: 80px;
}
 #desktop {
    padding-top: 70px;
    position: relative;
    padding: 0px;
}

@media (max-width: 768px) {
  #mobile {
   display: block;
    width: 100%;
    background: whitesmoke;
  }
  #desktop {
    display: none;
  }
 
}
#footer {
  height: 40px;
  padding: 0px 0px 0px 60px;

}

 