body, #body {
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  /* font-variation-settings:"slnt" 0; */
    

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p h4, h5, h6 {
  margin-bottom: 0px;
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
ol, ul {
    padding-left: 0px;
}
/* #root {
  box-sizing: content-box;
  width: 100%;
} */


/* *, *::before, *::after {
  box-sizing: border-box;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}